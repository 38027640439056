<template>
  <div class="container pt-5">
    <div class="faq-herosection mb-5">
      <div class="col-6">
        <h1>iAppraise Help Center</h1>
        <p>
          Encountered an issue with our app? <br />
          Here are some solutions to common questions faced by our customer. Click the button down below if you're
          interested on giving us a try!
        </p>

        <BookDemoButton />
      </div>
      <div class="col-6">
        <div class="faq-illustration">
          <img
            src="/assets/img/iappraise-customer-service-min.jpg"
            alt="iAppraise-customer-service-image"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
  <div id="faq">
    <div class="container mb-5 pb-5">
      <h2 class="mb-5">General Questions</h2>
      <div class="col">
        <BaseAccordion :items="generalQuestions" name="general-questions" />
      </div>
    </div>

    <div class="faq-container container mx-0 px-0 pb-5 mb-5">
      <div class="col-12 col-md-6 p-0">
        <div class="wholesaler-illustration">
          <img src="/assets/img/wholesalers-mobile.png" alt="iAppraise-wholesalers-mobile-image" class="img-fluid" />
        </div>
      </div>
      <div class="offset-md-1 col-12 col-md-6 wholesalers">
        <h2 class="mb-5">For Wholesalers</h2>
        <BaseAccordion name="for-wholesalers" :items="forWholesalers" />
      </div>
    </div>

    <div class="faq-container container mx-0 px-0 pb-5 mb-5">
      <div class="offset-md-1 col-12 col-md-6 webplatformers">
        <h2>For Web Platform</h2>
        <BaseAccordion name="for-web-platform" :items="forWebPlatform" />
      </div>
      <div class="col-12 col-md-7 text-end p-0">
        <div class="webplatform-illustration">
          <img src="/assets/img/webplatform-showcase.png" alt="iAppraise-wholesalers-mobile-image" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap";
import BookDemoButton from "@/components/Buttons/BookDemoButton.vue";
import BaseAccordion from "@/components/Accordions/BaseAccordion.vue";

export default {
  name: "HelpCenter",
  components: { BookDemoButton, BaseAccordion },
  data: function () {
    return {
      generalQuestions: [
        {
          title: "How do I print off a PDF of my Appraisal?",
          description:
            "Go into the web platform, search for the desired appraisal via the search bar at the top of the page, click into the appraisal, click print page via the button in the top left hand corner. Or you can go into the app and click the top right button symbol (paper plane) and email it to yourself, from there you simply print the PDF."
        },
        {
          title:
            "If I have sent an Appraisal to management, can I go back into the appraisal, change something, then re send it?",
          description:
            "As a sales consultant, once you have sent an appraisal to management you can't change what has been sent. Management however can edit information before sending to their selected wholesaler’s, so if needed, flag this with your manager and they can edit the appraisal."
        },
        {
          title: "How do I search for past appraisals?",
          description:
            "Simply go to the search bar once logged into the web platform on your PC. Appraisals can be searched by Make, Model, Colour, VIN plate, registration and client details."
        },
        {
          title: "How do I check what time and date a wholesaler put money on a vehicle?",
          description:
            "Once in the appraisal, at the top right hand side of the page there will be dates and times appraisals were sent and received."
        },
        {
          title: "Can I re-push appraisals to wholesalers?",
          description:
            "Yes, via the web platform, click on the appraisal and click on “Duplicate” — this will allow a re-push to wholesale as it reappears as an active appraisal on the app."
        },
        {
          title: "What information can the Wholesaler see?",
          description:
            "The Wholesaler will receive all the information on the vehicle Excluding any customer Personal details and financial details."
        }
      ],
      forWholesalers: [
        {
          title: "How do I download the APP?",
          description: `
          Simply go to the App store, search iAppraise, and download “<a href="https://itunes.apple.com/us/app/iappraise-for-wholesalers/id1215954767" >iAppraise for Wholesalers Apple User</a >” or “<a href="https://play.google.com/store/apps/details?id=com.iappraise.reseller" >iAppraise for Wholesalers Android User</a >” .`
        },
        {
          title: "How do I view the information sent to me by the Dealership?",
          description: `
          Once you have created an account by completing the sign up fields, the dealership will need to add you as a Favourite. Now the dealership can send you Appraisals to your smart phone, tablet or PC. You will receive a push notification once a dealership has sent an appraisal to you. To view, simply click on the received appraisals tab at the bottom of the home screen, and select the desired appraisal you wish to view.`
        },
        {
          title: "How do I send my price back to a dealership and is it binding?",
          description: `
          At the bottom of the Appraisal, there is a “$” icon. Push the icon to send a price through the app direct to the dealership that sent you the appraisal. Remember these offers are NOT binding by either party. Or you can call or email, the choice is yours.`
        },
        {
          title: "Do I have to send it through the app or can I still call or email?",
          description: `
          Your choice, simply click call or email and it will link you directly with the person who has sent you the appraisal.`
        },
        {
          title: "I would like the dealership to email me a copy of the appraisal, is this possible?",
          description: `
          Yes, it is, simply request the dealership to send you the appraisal as a PDF.`
        }
      ],
      forWebPlatform: [
        {
          title: "Does the Web platform notify me when an appraisal has been completed?",
          description: `No, notifications only occur on the smart phone or tablet however you can also receive e-mail notifications if required. The web platform does however populate in real time from the app.`
        },
        {
          title: "How do I search for data?",
          description: `Simply go to the search bar on the home screen once logged in, this is located at the top of the page.`
        },
        {
          title: "What data can be searched?",
          description: `Make, Model, Colour, VIN registration, customer details. Staff performance indicators.`
        },
        {
          title: "Can I print a PDF of an Appraisal from the web platform?",
          description: `Yes, search for an appraisal via the search bar, then select the appraisal by clicking on it. Just below the Photos at the top of the page, on the left-hand side there is a “Print” icon. Click this to print a PDF of the appraisal.`
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "@/scss/buttons.scss";
@import "@/scss/_variables.scss";

h1,
h2 {
  font-family: "sf_pro_displaybold";
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 76px;

  @media only screen and (max-width: 600px) {
    font-size: 42px;
    line-height: 50px;
  }
}

p {
  font-size: 18px;
  line-height: 140%;
}

.faq-herosection {
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }

  .faq-illustration {
    position: relative;
    padding: 0;
    margin: 50px 0 0 20px;

    @media only screen and (max-width: 600px) {
      margin: 0;
    }

    img {
      max-width: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      color: $COLOUR_YELLOW;
      background-color: $COLOUR_YELLOW;
      top: -10%;
      left: 10%;
      z-index: -1;

      @media only screen and (max-width: 800px) {
        height: 280px;
      }
    }
  }
}

.faq-container {
  display: flex;
  align-items: center;

  .wholesalers {
    padding-right: 10%;
  }

  .webplatformers {
    padding-right: 10%;
  }

  img {
    width: 100%;
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;

    .webplatform-illustration {
      margin-top: 50px;
    }
  }
}
</style>

<style lang="scss">
.accordion {
  .accordion-item {
    border: 0;

    .accordion-header {
      padding-right: 0 !important;

      .accordion-button {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -1px;

        &:focus {
          box-shadow: none;
          border-color: rgba(0, 0, 0, 0);
        }
      }

      .accordion-button.collapsed,
      .accordion-button:not(.collapsed) {
        background-color: white !important;
        color: black !important;
        padding: 0;
        padding-left: 42px;
      }

      .accordion-button.collapsed::after,
      .accordion-button:not(.collapsed)::after {
        position: absolute;
        left: 0;
      }

      .accordion-button:not(.collapsed) {
        &::after {
          background: url("/assets/img/icon-minus.svg") 50% 50% no-repeat;
        }
      }

      .accordion-button.collapsed {
        &::after {
          background: url("/assets/img/icon-plus.svg") 50% 50% no-repeat;
        }
      }
    }

    .accordion-body {
      font-size: 18px;
      line-height: 140%;
      font-family: "sf_pro_displayregular";
      font-style: normal;
      font-weight: 400;
      padding-left: 42px;
    }
  }
}
</style>
