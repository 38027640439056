<template>
  <router-link
    :to="{ name: 'BookDemo' }"
    class="buttons buttons-yellow book-demo-btn"
    style="padding: 18px 40px; margin: 20px 0; font-size: 20px"
    >Book Demo</router-link
  >
</template>

<script>
export default {
  name: "BookDemoButton"
};
</script>
