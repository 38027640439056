<template>
  <div class="accordion" :id="`accordion-${name}`">
    <div class="accordion-item my-4" v-for="(item, index) in items" :key="item.title">
      <h2 class="accordion-header" :id="`${name}-heading-${index}`">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#${name}-collapse-${index}`"
          aria-expanded="true"
          :aria-controls="`${name}-collapse-${index}`"
          v-html="item.title"
        ></button>
      </h2>
      <div
        :id="`${name}-collapse-${index}`"
        class="accordion-collapse collapse mt-3"
        :aria-labelledby="`${name}-heading-${index}`"
        :data-bs-parent="`#accordion-${name}`"
      >
        <div class="accordion-body" v-html="item.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap";

export default {
  name: "BaseAccordion",
  props: {
    /**
     * Accepts:
     * Array of objects containing the following attributes
     * - title
     *      - String
     * - description
     * - String
     */
    items: Array,
    /**
     * Name for ids
     */
    name: {
      type: String,
      required: false,
      default: "accordion"
    }
  }
};
</script>
